import React from "react";
import homeStyle from "../../style/home.module.less";

export const HomeSlide = (props) => {
  return (
    <div {...props}>
      <div className={homeStyle.homeSlide}>
        <div className={homeStyle.leftContainer}>
          <img src="/images/stonks51.png" width={500} />
        </div>
        <div className={homeStyle.rightContainer}>
          <h2 className={homeStyle.mainHeading}>
            <span className={homeStyle.specialFont}>STONKS &nbsp;</span>
            Launchpad
          </h2>
          <h4>
            Unleash the Power of Memes on the Fast Lane of Solana Blockchain
          </h4>

          <h2 className={homeStyle.homeSlideSubHeading}>
            Launch Your Memes to New Heights
          </h2>
          <p className={homeStyle.homeSlideDescription}>
            At <span className={homeStyle.specialFont}>STONKS</span>, we believe
            that memes have the power to change the world. Our launchpad
            empowers creators to unleash their most viral content on the Solana
            blockchain, where speed and scalability meet creativity.
          </p>
        </div>
      </div>
    </div>
  );
};
