import React from "react";
import homeStyle from "../../style/home.module.less";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export const RoadMap = (props) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      {breakpoint === "sm" ? (
        <div {...props}>
          <div className={homeStyle.roadMapContainer}>
            <div className={homeStyle.roadMapLeftContainer}>
              <img
                src="/images/stonks44.webp"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className={homeStyle.roadMapRightContainer}>
              <p
                className={`${homeStyle.sectionTitle} ${homeStyle.noBottomMargin}`}
              >
                Roadmap &nbsp;&nbsp;&nbsp;Unveiled:
              </p>
              <p className={homeStyle.sectionSubTitle}>
                Navigating the Future with STONKS
              </p>
              <ul>
                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    December: Setting the Stage
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Social Media Platform Launches
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Website & Token launch
                      </p>
                    </li>
                  </ol>
                </li>

                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    January: Building Community Foundations
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Community Building
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        LaunchPad V1 Launch
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Social Media Marketing
                      </p>
                    </li>
                  </ol>
                </li>
                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    Feb & March: Accelerating Momentum
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Traffic Growth on Platform
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        LaunchPad V1 Enhancements
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Community Decision on V2 Features
                      </p>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div {...props}>
          <div className={homeStyle.roadMapContainer}>
            <div className={homeStyle.roadMapLeftContainer}>
              <img
                src="/images/stonks44.webp"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className={homeStyle.roadMapRightContainer}>
              <p
                className={`${homeStyle.sectionTitle} ${homeStyle.noBottomMargin}`}
              >
                Roadmap &nbsp;&nbsp;&nbsp;Unveiled:
              </p>
              <p className={homeStyle.sectionSubTitle}>
                Navigating the Future with Stonks
              </p>
              <ul>
                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    December: Setting the Stage
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Social Media Platform Launches
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Official Twitter, Telegram, Medium accounts go live.
                        Engage with the crypto community, share updates, and
                        foster discussions.
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Website & Token launch
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Deploy the official Stonks website and Token launch.
                        Centralized hub for information, documentation, and
                        real-time updates.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    January: Building Community Foundations
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Community Building
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Establish dedicated forums for technical discussions,
                        feedback, and community support. Lay the groundwork for
                        a vibrant Stonks community.
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        LaunchPad V1 Launch
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Unveil the first iteration of Stonks LaunchPad. <br />
                        Users gain the power to list, manage, and participate in
                        token launches.
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Social Media Marketing
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Initiate targeted marketing campaigns on various
                        platforms.
                        <br />
                        Increase visibility and attract a broader audience.
                      </p>
                    </li>
                  </ol>
                </li>
                <li className={homeStyle.slideRoadMapItem}>
                  <p className={homeStyle.slideRoadMapItemTitle}>
                    February & March: Accelerating Momentum
                  </p>
                  <ol>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Traffic Growth on Platform
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Implement organic and strategic methods to drive traffic
                        to the Stonks platform.
                        <br />
                        Focus on increasing user acquisition and engagement.
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        LaunchPad V1 Enhancements
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Roll out updates to Stonks LaunchPad V1 based on user
                        feedback.
                        <br />
                        Community-driven insights guide platform improvements.
                      </p>
                    </li>
                    <li className={homeStyle.slideRoadMapItemContent}>
                      <p className={homeStyle.slideRoadMapItemContentMain}>
                        Community Decision on V2 Features
                      </p>
                      <p className={homeStyle.slideRoadMapItemContentDesc}>
                        Introduce decentralized governance mechanisms.
                        <br />
                        Empower the community to actively participate in
                        decision-making for future enhancements.
                      </p>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
