import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import headerStyle from "../style/header.module.less";
import { useBreakpoint } from "../hooks/useBreakpoint";

export const SlideHeader = () => {
  const breakpoint = useBreakpoint();

  console.log("breakpoint", breakpoint);
  return (
    <nav className={headerStyle.slideNavbar}>
      <div className="left">
        <Link to="/">
          <img
            src="/images/stonks54.png"
            alt="Logo"
            className={headerStyle.navBarItems}
          />
        </Link>
      </div>
      <div className={headerStyle.headTitle}>
        <h1>STONKS</h1>
      </div>
      <div className="right">
        <a
          href="https://twitter.com/StonksSolana"
          target="_blank"
          rel="noopener noreferrer"
          className={headerStyle.navLinksText}
        >
          {breakpoint === "sm" ? "X" : "TWITTER"}
        </a>
        <a
          href="https://t.me/stonksol"
          target="_blank"
          rel="noopener noreferrer"
          className={headerStyle.navLinksText}
        >
          {breakpoint === "sm" ? "Tg" : "Telegram"}
        </a>
        <a
          href="https://medium.com/@stonksSolana"
          target="_blank"
          rel="noopener noreferrer"
          className={headerStyle.navLinksText}
        >
          {breakpoint === "sm" ? "M" : "Medium"}
        </a>
      </div>
    </nav>
  );
};
