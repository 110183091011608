import React, { useEffect, useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { SlideHeader } from "./SlideHeader";
import { Tokenomics } from "./slides/Tokenomics";
import { RoadMap } from "./slides/RoadMap";
import { Vision } from "./slides/Vision";
import { About } from "./slides/About";
import { HomeSlide } from "./slides/HomeSlide";
import { ContactUs } from "./slides/ContactUs";
import { Homepage } from "./slides/Homepage";

const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

const originalColors = [
  "rgb(47 198 100)",
  "#ff5f45",
  "#3a86ff",
  "purple",
  "#7209b7",
  "orange",
  "#0798ec",
  "#ff006e",
  "#8338ec",
  "#3a86ff",
  "#fc6c7c",
];

export const SlideApp = () => {
  useEffect(() => {
    const watermark = document.querySelectorAll(".fp-watermark");
    watermark.forEach((item) => {
      item.style.display = "none";
    });
    console.log("watermarks", watermark);
  }, []);
  const Menu = () => (
    <div
      className="menu"
      style={{
        position: "fixed",
        top: 0,
        zIndex: 100,
        width: "100%",
      }}
    >
      <SlideHeader />
    </div>
  );
  const onLeave = () => {};

  return (
    <div>
      <Menu />

      <ReactFullpage
        debug
        licenseKey="temp"
        navigation
        anchors={[
          "#",
          "#home",
          "#about",
          "#vision",
          "#tokenomics",
          "roadmap",
          "joinus",
        ]}
        sectionSelector={SECTION_SEL}
        onLeave={onLeave}
        sectionsColor={originalColors}
        render={(comp) => (
          <ReactFullpage.Wrapper>
            <Homepage className={SEL} />
            <HomeSlide className={SEL} />
            <About className={SEL} />
            <Vision className={SEL} />
            <Tokenomics className={SEL} />
            <RoadMap className={SEL} />
            <ContactUs className={SEL} />
          </ReactFullpage.Wrapper>
        )}
      />
    </div>
  );
};
