import React from "react";
import homeStyle from "../../style/home.module.less";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export const About = (props) => {
  const breakpoint = useBreakpoint();
  return (
    <>
      {breakpoint === "sm" ? (
        <div {...props}>
          <div className={homeStyle.aboutContainer}>
            <div className={homeStyle.aboutLeftContainer}>
              <img src="/images/stonks55.jfif" />
            </div>
            <div className={homeStyle.aboutRightContainer}>
              <p
                className={`${homeStyle.aboutSectionTitle} ${homeStyle.silver}`}
              >
                ABOUT &nbsp;&nbsp;STONKS
              </p>
              <p className={`${homeStyle.aboutItemTitle} ${homeStyle.silver}`}>
                The Rise of Stonks :
              </p>
              <p className={`${homeStyle.aboutItemDesc} ${homeStyle.silver}`}>
                In the ever-expanding galaxy of cryptocurrency, $STONKS emerges
                as a cosmic force, a playful yet powerful meme that’s more than
                just a punchline. The intentional misspelling of “stocks” took
                the internet by storm, with its surreal meme featuring Meme Man
                standing in front of a stock market image, captioned simply as
                “Stonks.” Originally associated with jokes about poor financial
                decisions, we’re here to tell you that the narrative is about to
                take a cosmic shift.
              </p>
              <p className={`${homeStyle.aboutItemTitle} ${homeStyle.silver}`}>
                The Power of Stonks :
              </p>
              <p className={`${homeStyle.aboutItemDesc} ${homeStyle.silver}`}>
                Stonks, where innovation and decentralization converge to
                redefine the landscape of decentralized finance (DeFi). Founded
                on the principles of accessibility, innovation, and
                community-driven collaboration, Stonks is more than just a
                platform — it's a movement towards a future where financial
                opportunities are accessible to all.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div {...props}>
          <div className={homeStyle.aboutContainer}>
            <div className={homeStyle.aboutLeftContainer}>
              <img src="/images/stonks55.jfif" />
            </div>
            <div className={homeStyle.aboutRightContainer}>
              <p className={`${homeStyle.sectionTitle} ${homeStyle.silver}`}>
                ABOUT &nbsp;&nbsp;&nbsp; $STONKS
              </p>
              <p className={`${homeStyle.aboutItemTitle} ${homeStyle.silver}`}>
                The Rise of Stonks :
              </p>
              <p className={`${homeStyle.aboutItemDesc} ${homeStyle.silver}`}>
                In the ever-expanding galaxy of cryptocurrency, $STONKS emerges
                as a cosmic force, a playful yet powerful meme that’s more than
                just a punchline. The intentional misspelling of “stocks” took
                the internet by storm, with its surreal meme featuring Meme Man
                standing in front of a stock market image, captioned simply as
                “Stonks.” Originally associated with jokes about poor financial
                decisions, we’re here to tell you that the narrative is about to
                take a cosmic shift.
              </p>
              <br />
              <p className={`${homeStyle.aboutItemTitle} ${homeStyle.silver}`}>
                The Power of Stonks :
              </p>
              <p className={`${homeStyle.aboutItemDesc} ${homeStyle.silver}`}>
                Stonks, where innovation and decentralization converge to
                redefine the landscape of decentralized finance (DeFi). Founded
                on the principles of accessibility, innovation, and
                community-driven collaboration, Stonks is more than just a
                platform — it's a movement towards a future where financial
                opportunities are accessible to all.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
