import React, { useEffect, useRef } from "react";
import homeStyle from "../../style/home.module.less";
import { GlowCapture } from "@codaworks/react-glow";
import { Glow } from "@codaworks/react-glow";

export const Homepage = (props) => {
  const targetRef = useRef();
  useEffect(() => {
    const target = targetRef.current;
    console.log("target is", target);
    const flickerLetter = (letter) =>
      `<span style="animation: text-flicker-in-glow ${
        Math.random() * 4
      }s linear both ">${letter}</span>`;
    const colorLetter = (letter) =>
      `<span style="color: hsla(${
        Math.random() * 360
      }, 100%, 80%, 1);">${letter}</span>`;
    const flickerAndColorText = (text) =>
      text.split("").map(flickerLetter).map(colorLetter).join("");
    const neonGlory = (target) =>
      (target.innerHTML = flickerAndColorText(target.textContent));

    neonGlory(target);
    target.onclick = ({ target }) => neonGlory(target);
  }, []);
  return (
    <div {...props}>
      <div className={homeStyle.homepage}>
        <div className={homeStyle.leftContainer}>
          <img src="/images/stonks18.png" />
        </div>
        <div className={homeStyle.rightContainer}>
          <h1
            className={homeStyle.mainHeading}
            ref={targetRef}
            id="stonksHeading"
          >
            STONKS
          </h1>
          <h4>Redefining meaning of stonks </h4>

          <h2>One Stonk at a time</h2>

          <p className={homeStyle.homepageButtons}>
            <button
              class={`${homeStyle.customButton} ${homeStyle.buttonOneFive}`}
            >
              Buy it
            </button>
            <button
              class={`${homeStyle.customButton} ${homeStyle.buttonOneFive}`}
            >
              DexScreener
            </button>

            <button
              class={`${homeStyle.customButton} ${homeStyle.buttonOneFive}`}
            >
              BirdEye
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
