import React, { useState, useEffect } from "react";

export const BREAKPOINTS = { sm: 700, md: 750, lg: 992, xl: 1200, xxl: 1400 };

export const getBreakpoint = () => {
  if (window.matchMedia("(min-width: " + BREAKPOINTS.xxl + "px)").matches)
    return "xxl";
  if (window.matchMedia("(min-width: " + BREAKPOINTS.xl + "px)").matches)
    return "xl";
  if (window.matchMedia("(min-width: " + BREAKPOINTS.lg + "px)").matches)
    return "lg";
  if (window.matchMedia("(min-width: " + BREAKPOINTS.md + "px)").matches)
    return "md";
  return "sm";
};

/**
 * @name useBreakpoint
 * @function
 * @description A hook used to listen on device screen size change
 * and changes its value when breakpoint changed
 */
export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint);

  useEffect(() => {
    // Window resize listener
    const onResize = () => {
      // Refresh breakpoint
      setBreakpoint(getBreakpoint);
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return breakpoint;
};
