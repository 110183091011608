import React from "react";
import homeStyle from "../../style/home.module.less";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export const Vision = (props) => {
  const breakpoint = useBreakpoint();
  return (
    <>
      {breakpoint == "sm" ? (
        <div {...props}>
          <div className={homeStyle.visionContainer}>
            <div className={homeStyle.visionLeftContainer}>
              <div className={homeStyle.visionLeftContainerContent}>
                <p
                  className={`${homeStyle.visionSectionTitle} ${homeStyle.brightGreen}`}
                >
                  $STONKS VISION
                </p>
                <p className={homeStyle.slideVisionItemHeading}>
                  Unleashing the Power of Financial Freedom
                </p>
                <p className={homeStyle.slideVisionItemDetails}>
                  Our vision for $STONKS extends beyond the digital realm. It’s
                  a call to arms for crypto enthusiasts, investors, and meme
                  aficionados alike to join a movement that celebrates financial
                  innovation with a touch of humor. Imagine a world where stonks
                  aren’t just figures on a chart; they’re symbols of collective
                  achievement and shared success.
                </p>
              </div>
            </div>
            <div className={homeStyle.visionMidContainer}>
              <p className={homeStyle.slideVisionItemHeading}>
                Our Commitment:
              </p>
              <p className={homeStyle.slideVisionItemDetails}>
                <ol>
                  <li>Empowering Investors</li>
                  <li>Building Wealth Together</li>
                  <li>Transparency and Trust</li>
                </ol>
              </p>
            </div>
            <div className={homeStyle.visionRightContainer}>
              <p className={homeStyle.slideVisionItemHeading}>Our Promise:</p>
              <p className={homeStyle.slideVisionItemDetails}>
                <ol>
                  <li>
                    Innovation: Constantly pushing the boundaries of what's
                    possible
                  </li>
                  <li>
                    Transparency: Providing open and transparent communication
                    with our community.
                  </li>
                  <li>
                    Community Collaboration: Fostering a collaborative
                    environment where your voice matters.
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div {...props}>
          <div className={homeStyle.visionContainer}>
            <div className={homeStyle.visionLeftContainer}>
              <img
                src="/images/stonks47.jpg"
                style={{ border: "10px solid #00ff00", borderRadius: "100px" }}
              />
            </div>
            <div className={homeStyle.visionRightContainer}>
              <p
                className={`${homeStyle.visionSectionTitle} ${homeStyle.brightGreen}`}
              >
                $STONKS VISION
              </p>
              <p className={homeStyle.slideVisionItemHeading}>
                Unleashing the Power of Financial Freedom
              </p>
              <p className={homeStyle.slideVisionItemDetails}>
                Our vision for $STONKS extends beyond the digital realm. It’s a
                call to arms for crypto enthusiasts, investors, and meme
                aficionados alike to join a movement that celebrates financial
                innovation with a touch of humor. Imagine a world where stonks
                aren’t just figures on a chart; they’re symbols of collective
                achievement and shared success.
              </p>
              <p className={homeStyle.slideVisionItemHeading}>
                Our Commitment:
              </p>
              <p className={homeStyle.slideVisionItemDetails}>
                <ol>
                  <li>Empowering Investors</li>
                  <li>Building Wealth Together</li>
                  <li>Transparency and Trust</li>
                </ol>
              </p>
              <p className={homeStyle.slideVisionItemHeading}>Our Promise:</p>
              <p className={homeStyle.slideVisionItemDetails}>
                <ol>
                  <li>
                    Innovation: Constantly pushing the boundaries of what's
                    possible
                  </li>
                  <li>
                    Transparency: Providing open and transparent communication
                    with our community.
                  </li>
                  <li>
                    Community Collaboration: Fostering a collaborative
                    environment where your voice matters.
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
