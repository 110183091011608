import React, { useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Header } from "./components/Header";
import { SlideApp } from "./components/SlideApp";
import { BrowserRouter as Router, Route } from "react-router-dom";

const App2 = () => {
  return (
    <Router>
      <SlideApp />
    </Router>
  );
};
export default App2;
