import React from "react";
import homeStyle from "../../style/home.module.less";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export const ContactUs = (props) => {
  const breakpoint = useBreakpoint();
  return (
    <>
      {breakpoint == "sm" ? (
        <div {...props}>
          <div className={homeStyle.contactUsContainer}>
            <div className={homeStyle.contactUsLeftContainer}>
              <img src="/images/stonks57.png" />
            </div>
            <div className={homeStyle.contactUsRightContainer}>
              <p
                className={`${homeStyle.sectionTitle} ${homeStyle.silverShade}`}
              >
                HOW &nbsp;&nbsp;TO &nbsp;&nbsp; JOIN &nbsp;&nbsp;
              </p>
              <p className={homeStyle.slideContactItems}>
                <ol>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Follow Us for Updates:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Stay informed by following our official accounts on
                      Twitter and Telegram.
                    </p>
                  </li>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Community Participation:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Dive into Stonks' technical ecosystem on our website.
                      Discover Stonks LaunchPad's features, upcoming token
                      launches, and essential technical resources.
                    </p>
                  </li>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Token Launch Engagement:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Actively participate in token launches on Stonks
                      LaunchPad. Contribute to groundbreaking projects and be an
                      integral part of the Stonks community.
                    </p>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div {...props}>
          <div className={homeStyle.contactUsContainer}>
            <div className={homeStyle.contactUsLeftContainer}>
              <img src="/images/stonks57.png" />
            </div>
            <div className={homeStyle.contactUsRightContainer}>
              <p
                className={`${homeStyle.sectionTitle} ${homeStyle.silverShade}`}
              >
                HOW &nbsp;&nbsp;TO &nbsp;&nbsp; JOIN &nbsp;&nbsp;
              </p>
              <p className={homeStyle.slideContactItems}>
                <ol>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Follow Us for Updates:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Stay informed by following our official accounts on
                      Twitter and Telegram. Get real-time updates, news, and
                      announcements.
                    </p>
                  </li>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Community Participation:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Dive into Stonks' technical ecosystem on our website.
                      Discover Stonks LaunchPad's features, upcoming token
                      launches, and essential technical resources.Engage in
                      technical discussions on Telegram and our dedicated
                      forums. Connect with blockchain enthusiasts, share your
                      expertise, and collaborate on innovative solutions.
                    </p>
                  </li>
                  <li className={homeStyle.slideContactContent}>
                    <p className={homeStyle.slideContactContentMain}>
                      Token Launch Engagement:
                    </p>
                    <p className={homeStyle.slideContactContentDesc}>
                      Actively participate in token launches on Stonks
                      LaunchPad. Contribute to groundbreaking projects and be an
                      integral part of the Stonks community.
                    </p>
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
