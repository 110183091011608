import React from "react";
import homeStyle from "../../style/home.module.less";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  cutoutPercentage: 90, //
  plugins: {
    legend: {
      labels: {
        color: "#ffffff",
      },
    },
  },
};

export const Tokenomics = (props) => {
  const data = {
    labels: ["Presale", "Launch", "Marketing", "Team"],
    datasets: [
      {
        label: "STONKS Distribution",
        data: [50000000, 30000000, 10000000, 10000000],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(186,85,211)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div {...props}>
      <div className={homeStyle.tokenomicsContainer}>
        <div className={homeStyle.tokenomicsLeftContainer}>
          <Doughnut data={data} options={options} />
          <img src="/gifs/m2-res_176p.gif" />
          {/* <img src="/images/pie-chart.svg" width={500} /> */}
        </div>
        <div className={homeStyle.tokenomicsRightContainer}>
          <p
            className={`${homeStyle.sectionTitle} ${homeStyle.whiteSmokeText}`}
          >
            TOKENOMICS
          </p>
          <ul className={homeStyle.slideTokenomicsItems}>
            <li>Total Supply : 100,000,000$STONKS</li>
            <li>Taxes : 0%</li>
            <li>Chain : Solana</li>
            <li>Presale : 50% (50Million $STONKS) </li>
            <li>Launch : 30% (30Million $STONKS)</li>
            <li>Community and marketing : 10% (10Million $STONKS)</li>
            <li>Team : 10% (10Million $STONKS)</li>
            <li>Returns: INFINITEEEEEEEEEEE</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
